<template>
  <div class="slsPageBox">
    <img src="@/assets/image/none_page.png" alt="404">
    <div class="routerBox">
      <p class="text">
        404_未找到访问页面
      </p>
      <p class="text">
        此功能页面正在建设中，敬请期待...
      </p>
      <router-link :to="{path:'/'}">
        看首页
      </router-link>
      <router-link :to="{path:'/login'}">
        去登录
      </router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.slsPageBox{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  img{
    width: 45.32vw;
  }
  .routerBox{
    text-align: center;
    font-size: 14px;
    margin-top: 12px;
    .text:first-child{
      font-size: 24px;
      color: #000;
    }
    .text:nth-child(2){
      font-size: 20px;
      color: #666;
      margin-top: 8px;
      margin-bottom: 20px;
    }
    a{
      text-decoration: underline;
    }
    a:first-of-type{
      color: #333;
    }
    a:last-of-type{
      color: #0089FF;
      margin-left: 12px;
    }
  }
}
</style>
